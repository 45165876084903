myApp.factory('spvModel', ['$http', function ($http) {
	return {
		getLastID: function() {
			return $http.get(baseUrl + 'spv/get/last_id');
		},
		getAnaliseCount: function() {
			return $http.get(baseUrl + 'spv/get/AnaliseCount');
		},
		getCursoCount: function() {
			return $http.get(baseUrl + 'spv/get/CursoCount');
		},
		getReprovedCount: function() {
			return $http.get(baseUrl + 'spv/get/ReprovedCount');
		},
		getUniqueCase: function(id) {
			return $http.get(baseUrl + 'spv/get/unique_case/' + id);
		},
		saveReclamation : function(Reclamation_add, last_id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'spv/save/reclamation',
				method: "POST",
				data: {
					count: last_id,
					date : Reclamation_add.Date,
					assunto : Reclamation_add.Assunto,
					fa : Reclamation_add.FA,
					fp : Reclamation_add.FP,
					localizacao_produto: Reclamation_add.Localizacao_produto,
					morada_entrega: Reclamation_add.Morada_entrega,
					data_envio: Reclamation_add.Data_envio,
					nome_cliente: Reclamation_add.Nome_cliente,
					pais_cliente: Reclamation_add.Pais_cliente,
					morada_recolha: Reclamation_add.Morada_recolha,
					transporte_envio: Reclamation_add.Transporte_envio,

					reincidente: Reclamation_add.Reincidente,
					cr: Reclamation_add.CR,
					exposicao_reclamacao: Reclamation_add.Exposicao_reclamacao,
					disposto_negociar: Reclamation_add.Disposto_negociar,
					existe_stock: Reclamation_add.Existe_stock,
					analise_vendedor: Reclamation_add.Analise_vendedor,



				}
			});
		},
		UpdateReclamation: function(Reclamation, id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'spv/edit/reclamation/' + id,
				method: "POST",
				data: {
					count: Reclamation.count,
					assunto : Reclamation.assunto,
					fa : Reclamation.FA,
					fp : Reclamation.FP,
					localizacao_produto: Reclamation.pais_localizacao,
					morada_entrega: Reclamation.morada_entrega,
					data_envio: Reclamation.data_envio,
					nome_cliente: Reclamation.cliente,
					pais_cliente: Reclamation.pais_cliente,
					morada_recolha: Reclamation.local_recolha,
					transporte_envio: Reclamation.transporte,

					reincidente: Reclamation.reincidente,
					cr: Reclamation.cr,
					exposicao_reclamacao: Reclamation.exposicao_reclamacao,
					disposto_negociar: Reclamation.disposto_negociar,
					existe_stock: Reclamation.existe_stock,
					analise_vendedor: Reclamation.analise_vendedor,



				}
			});
		},
		UpdateReclamation_2: function(Quiz, id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'spv/edit/reclamation_2/' + id,
				method: "POST",
				data: {
					comentario : Quiz.comentario,
					comentario_decision : Quiz.comentario_decision,
					reparacao_local : Quiz.reparacao_local,
					reparacao_local_value : Quiz.reparacao_local_value,
					recolher_produto : Quiz.recolher_produto,
					recolher_produto_value : Quiz.recolher_produto_value,
					enviar_novo_produto : Quiz.enviar_novo_produto,
					enviar_novo_produto_value : Quiz.enviar_novo_produto_value,
					reparacao : Quiz.reparacao,
					reparacao_value : Quiz.reparacao_value,
					existe_stock : Quiz.existe_stock,
					existe_stock_value : Quiz.existe_stock_value,
					existe_compensacao : Quiz.existe_compensacao,
					existe_compensacao_value : Quiz.existe_compensacao_value,
					compensacao_explicacao : Quiz.compensacao_explicacao,
					disposto_comprar_peca_reclamada : Quiz.disposto_comprar_peca_reclamada,
					disposto_comprar_peca_reclamada_value : Quiz.disposto_comprar_peca_reclamada_value,
					responsavel_value : Quiz.responsavel_value,

				}
			});
		},
		UpdateReclamation_3: function(Quiz, id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'spv/edit/reclamation_3/' + id,
				method: "POST",
				data: {
					ponto_situacao : Quiz.ponto_situacao,
					data_prevista : Quiz.data_prevista,
					po_identificacao : Quiz.po_identificacao,
					week_previsao : Quiz.week_previsao,
					status : Quiz.status,
					conclusao : Quiz.conclusao,

				}
			});
		},
		getReclamations: function() {
			return $http.get(baseUrl + 'spv/get/reclamations');
		},
		getReclamations_Curso: function() {
			return $http.get(baseUrl + 'spv/get/reclamations_curso');
		},
		getReclamations_Reproved: function() {
			return $http.get(baseUrl + 'spv/get/reclamations_reproved');
		},
		getReclamations_Archived: function() {
			return $http.get(baseUrl + 'spv/get/reclamations_archived');
		},
		getReclamations_Filter: function(word) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'spv/get/reclamations_filter',
				method: "POST",
				data: {
					word: word

				}
			});
		},
		getReclamations_Filter_Date: function(date_start, date_end) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'spv/get/reclamations_filter_date',
				method: "POST",
				data: {
					date_start: date_start,
					date_end: date_end,

				}
			});
		},
		updateValue: function(value, id, type) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'spv/update/value',
				method: "POST",
				data: {
					value : value,
					id : id,
					type : type,

				}
			});
		},
		updateComment: function(value, id, type) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'spv/update/comment',
				method: "POST",
				data: {
					value : value,
					id : id,
					type : type,

				}
			});
		},
		updatePhase3: function(value, id, type) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'spv/update/phase_3',
				method: "POST",
				data: {
					value : value,
					id : id,
					type : type,

				}
			});
		},
		Aprovar_phase2: function(id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'spv/aprovar/' + id,
				method: "POST",
			});
		},
		Reprovar_phase2: function(id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'spv/reprovar/' + id,
				method: "POST",
			});
		},
		Close_phase3: function(id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'spv/close/' + id,
				method: "POST",
			});
		},
		Return_Analise: function(id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'spv/return_analise/' + id,
				method: "POST",
			});
		},
		getFiles: function(id) {
			return $http.get(baseUrl + 'spv/get/files/' + id);
		},
		deleteFile: function(id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'spv/delete/file/' + id,
				method: "POST",
			});
		},
		Eliminar_Caso: function(id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'spv/delete/case/' + id,
				method: "POST",
			});
		},
	};
}])