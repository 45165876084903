myApp.factory('userModel', ['$http', '$cookies',  function($http, $cookies){
	var userModel = {};
	userModel.doLogin = function(loginData) {
			return $http({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'auth',
				method: "POST",
				data: {
					email: loginData.email,
					password: loginData.password
				}
			}).success(function(response) {
				$cookies.put('auth', JSON.stringify(response));
			}).error(function(data, status, headers) {
				console.log(data, status, headers);
			});
	};

	userModel.getAuthStatus = function() {
		var status = $cookies.get('auth');
		if(status) {
			return true;
		} else {
			return false;
		}
	};

	userModel.getUserObject = function () {
		var userObj = angular.fromJson($cookies.get('auth'));
		return userObj;
	}

	userModel.getUserState = function () {
		var userObj = angular.fromJson($cookies.get('auth'));
		state = userObj.state;
		cr = userObj.cr;

		if(state == "admin" && cr == 0)
		{
	
			items = 
    		[
    			{link: "nova adjudicação",  url: "#/orders/new-adjudication",  glyph: "icons/new.png", section:"new_adju"},
    			{link: "add stock",  url: "#/orders/new-stock",  glyph: "icons/insert_stock.png", section:"new_stock"},

    			{link: "dashboard",  url: "#/dashboard",  glyph: "icons/dashboard.png", section:"dashboard"},
    			{link: "calendário",  url: "#/calendar_360",  glyph: "icons/calendar.png", section:"dashboard"},
    			{link: "overview",  url: "#/calendar_overview",  glyph: "icons/overview.png", section:"dashboard"},
    			{link: "performance",  url: "#/performance/problem",  glyph: "icons/performance.png", section:"performance"}, 

    			{link: "encomendas",  url: "#/orders/manage",  glyph: "icons/encomendas.png", section:"encomendas"}, 

    			{link: "spv processos",  url: "#/spv_form",  glyph: "icons/spv.png", section:"spv"}, 

    			{link: "transportes",  url: "javascript:void(0)",  glyph: "icons/transportes.png", section:"transportes"}, 

    			{link: "arquivo",  url: "javascript:void(0)",  glyph: "icons/arquivo_main.png", section:"archive_main"},
    			{link: "arquivo",  url: "#/orders/archive?path=archive",  glyph: "icons/arquivo.png", section:"archive"},
    			{link: "pendentes",  url: "#/orders/archive?path=pendentes",  glyph: "icons/time.png", section:"archive"},
    			{link: "clientes em armazém",  url: "#/orders/archive?path=armazem",  glyph: "icons/armazem.png", section:"archive"},
    			{link: "controlo stock",  url: "#/orders/archive?path=stock",  glyph: "icons/stock.png", section:"archive"},
    			{link: "expedido",  url: "#/orders/archive?path=expedido",  glyph: "icons/expedido.png", section:"archive"},
    			{link: "reparações",  url: "#/orders/archive?path=repair",  glyph: "icons/repair.png", section:"archive"},
    			{link: "cemitério",  url: "#/orders/archive?path=cemetery",  glyph: "icons/cemiterio.png", section:"archive"},
    			{link: "reciclagem",  url: "#/orders/archive?path=recycle",  glyph: "icons/recycle.png", section:"archive"},

    			{link: "business unit",  url: "javascript:void(0)",  glyph: "icons/business.png", section:"business_main"},
    			{link: "imparidade",  url: "#/imparidade",  glyph: "icons/imparidade.png", section: "business"},
    			{link: "gestão de pagamentos",  url: "javascript:void(0)",  glyph: "icons/gestaopagamentos.png", section: "business"},
    			{link: "pendentes", url: "#/orders/queue", glyph: "icons/pendentes.png", section: "business"},
    			{link: "penalizações",  url: "javascript:void(0)",  glyph: "icons/penalizacoes.png", section: "business"},
    			{link: "análise",  url: "javascript:void(0)",  glyph: "icons/business_analise.png", section: "business"},


    			{link: "control", url: "javascript:void(0)", glyph: "icons/control.png", section: "control_main"},
    			{link: "vendedores",  url: "#/orders/manage_salesman",  glyph: "icons/vendedores.png", section: "control"},
    			{link: "controlo produtos",  url: "#/product/manage",  glyph: "icons/produtos.png", section: "control"},
    			{link: "controlo users",  url: "#/costumer/manage",  glyph: "icons/users.png", section: "control"},
    			{link: "controlo fornecedores",  url: "#/providers/manage",  glyph: "icons/fornecedores.png", section: "control"},
    			//{link: "Controlo Responsáveis",  url: "/people_preggo/manage",  glyph: "home", stroked: "#stroked-home"},
			];
		}
		//state admin vendedor
		if(state == "admin vendedor" && cr == 0)
		{
	
			items = 
    		[

    			{link: "dashboard",  url: "#/dashboard",  glyph: "icons/dashboard.png", section:"dashboard"},
    			{link: "calendário",  url: "#/calendar_360",  glyph: "icons/calendar.png", section:"dashboard"},
    			{link: "overview",  url: "#/calendar_overview",  glyph: "icons/overview.png", section:"dashboard"},
    			{link: "performance",  url: "#/performance/problem",  glyph: "icons/performance.png", section:"performance"}, 

    			{link: "encomendas",  url: "#/orders/manage",  glyph: "icons/encomendas.png", section:"encomendas"}, 

    			{link: "spv processos",  url: "#/spv_analyze",  glyph: "icons/spv.png", section:"spv"}, 

    			{link: "arquivo",  url: "javascript:void(0)",  glyph: "icons/arquivo_main.png", section:"archive_main"},
    			{link: "arquivo",  url: "#/orders/archive?path=archive",  glyph: "icons/arquivo.png", section:"archive"},
    			{link: "pendentes",  url: "#/orders/archive?path=pendentes",  glyph: "icons/time.png", section:"archive"},
    			{link: "clientes em armazém",  url: "#/orders/archive?path=armazem",  glyph: "icons/armazem.png", section:"archive"},
    			{link: "controlo stock",  url: "#/orders/archive?path=stock",  glyph: "icons/stock.png", section:"archive"},
    			{link: "expedido",  url: "#/orders/archive?path=expedido",  glyph: "icons/expedido.png", section:"archive"},
    			{link: "reparações",  url: "#/orders/archive?path=repair",  glyph: "icons/repair.png", section:"archive"},
    			{link: "cemitério",  url: "#/orders/archive?path=cemetery",  glyph: "icons/cemiterio.png", section:"archive"},

    			{link: "business unit",  url: "javascript:void(0)",  glyph: "icons/business.png", section:"business_main"},
    			{link: "gestão de pagamentos",  url: "javascript:void(0)",  glyph: "icons/gestaopagamentos.png", section: "business"},
    			{link: "pendentes", url: "#/orders/queue", glyph: "icons/pendentes.png", section: "business"},
    			{link: "penalizações",  url: "javascript:void(0)",  glyph: "icons/penalizacoes.png", section: "business"},
    			{link: "análise",  url: "javascript:void(0)",  glyph: "icons/business_analise.png", section: "business"},

    			//{link: "Controlo Responsáveis",  url: "/people_preggo/manage",  glyph: "home", stroked: "#stroked-home"},
			];
		}
		else if(state == "preggo" || cr == 1)
		{
			items = 
			[
				{link: "dashboard",  url: "#/dashboard_preggo",  glyph: "icons/dashboard.png", section: "dashboard"},
				{link: "calendário",  url: "#/calendar_360",  glyph: "icons/calendar.png", section:"dashboard"},
				{link: "overview",  url: "#/calendar_overview",  glyph: "icons/overview.png", section:"dashboard"},
    			{link: "performance",  url: "#/performance/problem",  glyph: "icons/performance.png", section:"performance"}, 
    			{link: "encomendas atuais",  url: "#/orders/manage_preggo",  glyph: "icons/encomendas.png", section: "user_2"},
			];
		}
		// FOR NOW THATS NOT ACTIVE
		/*else if(state == "adjudication" && cr == 0)
		{
			items = 
			[
				{link: "dashboard",  url: "/dashboard",  glyph: "dashboard-dial"},
    			{link: "nova adjudicação",  url: "/orders/new-adjudication",  glyph: "blank document"},
    			{link: "encomendas",  url: "/orders/manage_adjudication",  glyph: "table"},
    			{link: "arquivo",  url: "/orders/archive_adjudication",  glyph: "internal hard drive"},
			];
		}*/
		else if(state == "vendedor" && cr == 0)
		{
			items = 
			[
				{link: "dashboard",  url: "#/dashboard",  glyph: "icons/dashboard.png", section:"user_2"},
				{link: "calendário",  url: "#/calendar_360",  glyph: "icons/calendar.png", section:"user_2"},
				{link: "overview",  url: "#/calendar_overview",  glyph: "icons/overview.png", section:"user_2"},
    			{link: "encomendas",  url: "#/orders/manage_salesman",  glyph: "icons/encomendas.png", section: "user_2"},
    			{link: "performance",  url: "#/performance/problem",  glyph: "icons/performance.png", section:"performance"}, 
    			{link: "arquivo",  url: "#/orders/archive_salesman?path=archive",   glyph: "icons/arquivo.png", section: "user_2"},
    			{link: "spv processos",  url: "#/spv_analyze",  glyph: "icons/spv.png", section:"user_2"}, 
			];
		}
		return items;
		

		
	}

	userModel.doUserLogout = function() {
		$cookies.remove('auth');
	};

	return userModel;
}])