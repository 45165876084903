myApp.factory('providerModel', ['$http', function ($http) {
	return {
		saveProvider : function(ProviderData) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'save/provider',
				method: "POST",
				data: {
					nome: ProviderData.Nome
					//orderDim[0].p_width

				}
			});
		},
		getProviders: function() {
			return $http.get(baseUrl + 'get/providers');
		},
		deleteProvider: function(id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'delete/provider',
				method: "POST",
				data: {
					id: id
				}
			});
		},

	};


}])