myApp.factory('imparidadeModel', ['$http', function ($http) {
	return {
		getImparidades: function() {
			return $http.get(baseUrl + 'get/imparidades');
		},
		getImparidadesDetails: function() {
			return $http.get(baseUrl + 'get/imparidades_details');
		},
		deleteImparidade: function(id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'delete/imparidade/' + id,
				method: "POST",
			});
		},
		AddImparidade: function (Imparidade) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'add/imparidade',
				method: "POST",
				data: {
					caso : Imparidade.caso,
					responsaveis : Imparidade.responsaveis,
					data : Imparidade.data,
					valor : Imparidade.valor,
					descricao : Imparidade.descricao,

				}
			});
		},
		editImparidade: function (Imparidade, item) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'edit/imparidade/' + item,
				method: "POST",
				data: {
					caso : Imparidade.caso,
					responsaveis : Imparidade.responsaveis,
					data : Imparidade.data,
					valor : Imparidade.valor,
					descricao : Imparidade.descricao,

				}
			});
		},

		getUniqueImparidade: function(id) {
			return $http.get(baseUrl + 'get/unique_imparidade/' + id);
		},


	};


}])