myApp.factory('responsavelModel', ['$http', function ($http) {
	return {
		saveResponsavel : function(RData) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'save/responsavel_preggo',
				method: "POST",
				data: {
					nome: RData.Nome
					//orderDim[0].p_width

				}
			});
		},
		getResponsavel: function() {
			return $http.get(baseUrl + 'get/responsavel_preggo');
		},
		deleteResponsavel: function(id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'delete/responsavel_preggo',
				method: "POST",
				data: {
					id: id
				}
			});
		},

	};


}])