myApp.factory('costumerModel', ['$http', function ($http) {
	return {
		saveCostumer : function(CostumerData) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'save/costumer',
				method: "POST",
				data: {
					nome: CostumerData.Nome,
					sigla: CostumerData.Sigla,
					email: CostumerData.Email,
					password: CostumerData.Password,
					state: CostumerData.State
					//orderDim[0].p_width

				}
			});
		},
		getCostumers: function() {
			return $http.get(baseUrl + 'get/costumers');
		},
		deleteCostumer: function(id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'delete/costumer',
				method: "POST",
				data: {
					id: id
				}
			});
		},
		StateChecked: function (id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'put/responsavel/' + id,
				method: "POST",
			});
		},
		StateNOTChecked: function (id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'put/not_responsavel/' + id,
				method: "POST",
			});
		},
		updateRow : function(value, type, id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'update/row_user',
				method: "POST",
				data: {
					value : value,
					type: type,
					id: id,

				}
			});
		},

	};


}])