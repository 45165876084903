myApp.factory('productModel', ['$http', function ($http) {
	return {

		deleteProduct: function(id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'delete/product',
				method: "POST",
				data: {
					id: id
				}
			});
		},
		editProduct: function (Product, item) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'edit/product/' + item,
				method: "POST",
				data: {
					collection : Product.collection,
					name : Product.name,
					categoria : Product.categoria,
					version : Product.version,
					finish : Product.finish,
					codigo : Product.codigo,
					diameter: Product.diameter,
					width: Product.width,
					depth: Product.depth,
					height: Product.height,
					valor_producao: Product.valor_producao,
					pvp: Product.pvp
					//orderDim[0].p_width

				}
			});
		},
		addProduct: function (Product) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'add/product',
				method: "POST",
				data: {
					collection : Product.collection,
					name : Product.name,
					categoria : Product.categoria,
					version : Product.version,
					finish : Product.finish,
					codigo : Product.codigo,
					diameter: Product.diameter,
					width: Product.width,
					depth: Product.depth,
					height: Product.height,
					valor_producao: Product.valor_producao,
					pvp: Product.pvp
					//orderDim[0].p_width

				}
			});
		},

	};


}])