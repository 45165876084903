myApp.factory('dashboardModel', ['$http', function ($http) {
	return {
		getGraphSpec: function(year, local) {
			return $http.get(baseUrl + 'get/graph_spec/' + year + '/' + local);
		},
		getGraphTypeProductSum: function(year, local) {
			return $http.get(baseUrl + 'get/graph_type_product_sum/' + year + '/' + local);
		},

		getGraphTotalCliente: function(year, local) {
			return $http.get(baseUrl + 'get/graph_total_cliente/' + year + '/' + local);
		},
		getGraphTypeProduct: function(year, local) {
			return $http.get(baseUrl + 'get/graph_type_product/' + year + '/' + local);
		},

		getGraphTotalStock: function(year, local) {
			return $http.get(baseUrl + 'get/graph_total_stock/' + year + '/' + local);
		},
		getGraphTypeProductStock: function(year, local) {
			return $http.get(baseUrl + 'get/graph_type_product_stock/' + year + '/' + local);
		},

		//VENDEDOR DASHBOARD
		getGraphSpec_Vendedor: function(year, local, sigla) {
			return $http.get(baseUrl + 'get/graph_spec/' + year + '/' + local + '/' + sigla);
		},
		getGraphTypeProductSum_Vendedor: function(year, local, sigla) {
			return $http.get(baseUrl + 'get/graph_type_product_sum/' + year + '/' + local + '/' + sigla);
		},

		getGraphTotalCliente_Vendedor: function(year, local, sigla) {
			return $http.get(baseUrl + 'get/graph_total_cliente/' + year + '/' + local + '/' + sigla);
		},
		getGraphTypeProduct_Vendedor: function(year, local, sigla) {
			return $http.get(baseUrl + 'get/graph_type_product/' + year + '/' + local + '/' + sigla);
		},

		getGraphTotalStock_Vendedor: function(year, local, sigla) {
			return $http.get(baseUrl + 'get/graph_total_stock/' + year + '/' + local + '/' + sigla);
		},
		getGraphTypeProductStock_Vendedor: function(year, local, sigla) {
			return $http.get(baseUrl + 'get/graph_type_product_stock/' + year + '/' + local + '/' + sigla);
		},

		//PREGGO DASHBOARD
		getGraphSpec_Preggo: function(year) {
			return $http.get(baseUrl + 'get/graph_spec_preggo/' + year);
		},
		getGraphTypeProductSum_Preggo: function(year) {
			return $http.get(baseUrl + 'get/graph_type_product_sum_preggo/' + year);
		},

		getGraphTotalCliente_Preggo: function(year) {
			return $http.get(baseUrl + 'get/graph_total_cliente_preggo/' + year);
		},
		getGraphTypeProduct_Preggo: function(year) {
			return $http.get(baseUrl + 'get/graph_type_product_preggo/' + year);
		},

		getGraphTotalStock_Preggo: function(year) {
			return $http.get(baseUrl + 'get/graph_total_stock_preggo/' + year);
		},
		getGraphTypeProductStock_Preggo: function(year) {
			return $http.get(baseUrl + 'get/graph_type_product_stock_preggo/' + year);
		},


		//PERFORMANCE ADMIN-----------------------------------------------------------------------------------------
		getHighlights: function(year) {
			return $http.get(baseUrl + 'get/highlights/' + year);
		},
		getUnderway: function(year) {
			return $http.get(baseUrl + 'get/underway/' + year);
		},
		getPerformance: function(week, year) {
			return $http.get(baseUrl + 'get/performance/' + week + '/' + year);
		},
		getFocoHighlights: function(week, year) {
			return $http.get(baseUrl + 'get/focos_highlights/' + week + '/' + year);
		},

		getDimensionProblem: function(week, year, type) {
			return $http.get(baseUrl + 'get/dimension_problem/' + week + '/' + year + '/' + type);
		},
		//PERFORMANCE VENDEDOR-----------------------------------------------------------------------------------------

		getHighlights_sales: function(year, cr) {
			return $http.get(baseUrl + 'get/highlights_sales/' + year + '/' + cr);
		},
		getUnderway_sales: function(year, cr) {
			return $http.get(baseUrl + 'get/underway_sales/' + year + '/' + cr);
		},
		getPerformance_sales: function(week, year, cr) {
			return $http.get(baseUrl + 'get/performance_sales/' + week + '/' + year + '/' + cr);
		},
		getFocoHighlights_sales: function(week, year, cr) {
			return $http.get(baseUrl + 'get/focos_highlights_sales/' + week + '/' + year + '/' + cr);
		},

		getDimensionProblem_sales: function(week, year, type, cr) {
			return $http.get(baseUrl + 'get/dimension_problem_sales/' + week + '/' + year + '/' + type + '/' + cr);
		},

		//PERFORMANCE ARQUIVO

		getArchivePerformance: function() {
			return $http.get(baseUrl + 'get/performance_archive');
		},
		getArchivePerformance_sales: function(cr) {
			return $http.get(baseUrl + 'get/performance_archive_sales/' + cr);
		},
	};
}])