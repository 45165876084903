myApp.factory('calendarModel', ['$http', function ($http) {
	return {
		//---------------------------------------------------ADMIN OVERVIEW ROUTES --------------------------------------------//
		getAtualWeek: function(week, year, mode, type) {
			return $http.get(baseUrl + 'get/calendar_atual_week/' + week + '/' + year + '/' + mode + '/' + type, { cache: true});
		},
		getNextWeek: function(week, year, mode, type) {
			return $http.get(baseUrl + 'get/calendar_next_week/' + week + '/' + year + '/' + mode + '/' + type, { cache: true});
		},
		getNextWeek_2: function(week, year, mode, type) {
			return $http.get(baseUrl + 'get/calendar_next_week_2/' + week + '/' + year + '/' + mode + '/' + type, { cache: true});
		},
		getNextWeek_3: function(week, year, mode, type) {
			return $http.get(baseUrl + 'get/calendar_next_week_3/' + week + '/' + year + '/' + mode + '/' + type, { cache: true});
		},

		//---------------------------------------------------VENDEDOR OVERVIEW ROUTES --------------------------------------------//
		getAtualWeek_sales: function(week, year, mode, type, cr) {
			return $http.get(baseUrl + 'get/calendar_atual_week_sales/' + week + '/' + year + '/' + mode + '/' + type + '/' + cr, { cache: true});
		},
		getNextWeek_sales: function(week, year, mode, type, cr) {
			return $http.get(baseUrl + 'get/calendar_next_week_sales/' + week + '/' + year + '/' + mode + '/' + type + '/' + cr, { cache: true});
		},
		getNextWeek_2_sales: function(week, year, mode, type, cr) {
			return $http.get(baseUrl + 'get/calendar_next_week_2_sales/' + week + '/' + year + '/' + mode + '/' + type + '/' + cr, { cache: true});
		},
		getNextWeek_3_sales: function(week, year, mode, type, cr) {
			return $http.get(baseUrl + 'get/calendar_next_week_3_sales/' + week + '/' + year + '/' + mode + '/' + type + '/' + cr, { cache: true});
		},

		//------------------------------------------------------------ 360ª -------------------------------------------------------//
		getAtualWeek_360: function(week, year, mode, type) {
			return $http.get(baseUrl + 'get/calendar_atual_week_360/' + week + '/' + year + '/' + mode + '/' + type, { cache: true});
		},
		getNextWeek_360: function(week, year, mode, type) {
			return $http.get(baseUrl + 'get/calendar_next_week_360/' + week + '/' + year + '/' + mode + '/' + type, { cache: true});
		},
		getNextWeek_360_2: function(week, year, mode, type) {
			return $http.get(baseUrl + 'get/calendar_next_week_360_2/' + week + '/' + year + '/' + mode + '/' + type, { cache: true});
		},
		getNextWeek_360_3: function(week, year, mode, type) {
			return $http.get(baseUrl + 'get/calendar_next_week_360_3/' + week + '/' + year + '/' + mode + '/' + type, { cache: true});
		},
		getNextWeek_360_4: function(week, year, mode, type) {
			return $http.get(baseUrl + 'get/calendar_next_week_360_4/' + week + '/' + year + '/' + mode + '/' + type, { cache: true});
		},
		getNextWeek_360_5: function(week, year, mode, type) {
			return $http.get(baseUrl + 'get/calendar_next_week_360_5/' + week + '/' + year + '/' + mode + '/' + type, { cache: true});
		},
		getNextWeek_360_6: function(week, year, mode, type) {
			return $http.get(baseUrl + 'get/calendar_next_week_360_6/' + week + '/' + year + '/' + mode + '/' + type, { cache: true});
		},
		getNextWeek_360_7: function(week, year, mode, type) {
			return $http.get(baseUrl + 'get/calendar_next_week_360_7/' + week + '/' + year + '/' + mode + '/' + type, { cache: true});
		},
		getNextWeek_360_8: function(week, year, mode, type) {
			return $http.get(baseUrl + 'get/calendar_next_week_360_8/' + week + '/' + year + '/' + mode + '/' + type, { cache: true});
		},
		getNextWeek_360_9: function(week, year, mode, type) {
			return $http.get(baseUrl + 'get/calendar_next_week_360_9/' + week + '/' + year + '/' + mode + '/' + type, { cache: true});
		},
		getNextWeek_360_10: function(week, year, mode, type) {
			return $http.get(baseUrl + 'get/calendar_next_week_360_10/' + week + '/' + year + '/' + mode + '/' + type, { cache: true});
		},
		getNextWeek_360_11: function(week, year, mode, type) {
			return $http.get(baseUrl + 'get/calendar_next_week_360_11/' + week + '/' + year + '/' + mode + '/' + type, { cache: true});
		},



	};
}])

