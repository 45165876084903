myApp.factory('userSettings', ['$http', function ($http) {
	return {
	saveInfo: function (UserInfo, id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'edit/user/' + id,
				method: "POST",
				data: {
					nome : UserInfo.name,
					pass : UserInfo.NewPassword,

				}
			});
		},
	getUserInfo : function(id) {
		return $http.get(baseUrl + 'get/user/' + id);
	},
	getPassword : function(id) {
		return $http.get(baseUrl + 'get/user_pass/' + id);
	}

	};


}])