myApp.factory('orderModel', ['$http', function ($http) {
	return {
		saveAdjudication : function(orderData, p_diameter, p_width, p_height, p_depth, Produto_add) {

			for (var key in Produto_add.NomeProduto) {
				if(typeof Produto_add.NP[key] === 'undefined')
				{
					Produto_add.NP[key] = "";
				}
				if(typeof Produto_add.Acabamentos[key] === 'undefined')
				{
					Produto_add.Acabamentos[key] = "";
				}

			}

			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'save/order',
				method: "POST",
				data: {
					date_adjudication : orderData.Data,
					date_delivered : orderData.Data_delivered,
					proforma: orderData.Proforma,
					ecf: orderData.ECF,
					specs: orderData.Specs,
					cr: orderData.CR,
					lt: orderData.LT,
					date_expected: orderData.Data_expected,
					nomeCliente: orderData.NomeCliente,
					po: orderData.PO,
					lt_preggo : orderData.LT_preggo,
					country: orderData.country,

					produto: Produto_add.NomeProduto,
					np: Produto_add.NP,
					qtnd: Produto_add.Qtnd,
					tipoDimensoes: Produto_add.TypeDimensions,
					diameter: p_diameter,
					width: p_width,
					height: p_height,
					depth: p_depth,
					tipologia_produto: Produto_add.TipoProd,
					acabamentos: Produto_add.Acabamentos,


				}
			});
		},
		saveStock : function(orderData, p_diameter, p_width, p_height, p_depth, p_VP, Produto_add) {

			for (var key in Produto_add.NomeProduto) {
				if(typeof Produto_add.NP[key] === 'undefined')
				{
					Produto_add.NP[key] = "";
				}
				if(typeof Produto_add.Acabamentos[key] === 'undefined')
				{
					Produto_add.Acabamentos[key] = "";
				}

			}

			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'save/stock',
				method: "POST",
				data: {
					date_adjudication : orderData.Data,
					specs: orderData.Specs,
					po: orderData.PO,

					produto: Produto_add.NomeProduto,
					np: Produto_add.NP,
					tipoStock: Produto_add.TypeArchive,
					tipoDimensoes: Produto_add.TypeDimensions,
					diameter: p_diameter,
					width: p_width,
					height: p_height,
					depth: p_depth,
					vp: p_VP,
					tipologia_produto: Produto_add.TipoProd,
					acabamentos: Produto_add.Acabamentos,


				}
			});
		},
		searchOrder: function(value, type) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'search/order',
				method: "POST",
				data: {
					value : value,
					type: type,

				}
			});
		},
		searchOrderPersonal: function(value, type, user) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'search/order_personal',
				method: "POST",
				data: {
					value : value,
					type: type,
					user: user,

				}
			});
		},
		updateRow : function(value, type, id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'update/row',
				method: "POST",
				data: {
					value : value,
					type: type,
					id: id,

				}
			});
		},
		updateRow_Product : function(value, type, id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'update/row_product',
				method: "POST",
				data: {
					value : value,
					type: type,
					id: id,

				}
			});
		},
		updateEnt_Armazem : function(value, id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'update/ent_armazem',
				method: "POST",
				data: {
					value : value,
					id: id,

				}
			});
		},
		getProducts: function() {
			return $http.get(baseUrl + 'get/products');
		},
		getDimensions: function(produto) {
			return $http.get(baseUrl + 'get/dimensions/' + produto, { cache: true});
		},
		editProdutoOrder: function(Produto, diameter, width, height, depth, id) {
				return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'edit/product_order/' + id,
				method: "POST",
				data: {
					produto_name : Produto.Nome,
					tipoDimensoes: Produto.TypeDimensions,
					diameter: diameter,
					width: width,
					height: height,
					depth: depth,


				}
			});

		},
		getUniqueNP: function(id) {
			return $http.get(baseUrl + 'get/po_by_np/' + id);
		},
		getAdjudications: function() {
			return $http.get(baseUrl + 'get/adjudications');
		},
		getAdjudications_1: function(start, number, tablestate) {
			console.log(start);
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'get/adjudications_1',
				method: "POST",
				data: {
					start: start,
					number: number,
					search: tablestate.search,
					sort: tablestate.sort,
				}

			});
		},
		getAdjudicationsPersonal: function(user, user_id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'get/adjudications_personal',
				method: "POST",
				data: {
					user : user,
					user_id: user_id,

				}
			});
		},

		getArchiveOrders: function(path) {
			return $http.get(baseUrl + 'get/archive/' + path);
		},
		getArchiveOrdersPersonal: function(user, user_id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'get/archive_personal',
				method: "POST",
				data: {
					user : user,
					user_id: user_id,

				}
			});
		},
		getArchiveCemetery: function() {
			return $http.get(baseUrl + 'get/archive_cemetery');
		},

		getCustomDimension: function(id) {
			return $http.get(baseUrl + 'get/dimension_custom/' + id);

		},
		getFornecedores: function(id) {
			return $http.get(baseUrl + 'get/fornecedores_active/' + id);

		},
		getCustomOrder: function(id) {
			return $http.get(baseUrl + 'get/order/' + id);

		},
		getResponsaveis: function(id) {
			return $http.get(baseUrl + 'get/responsaveis');

		},
		editResponsavelPreggo: function (order, item) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'edit/responsavel/' + item,
				method: "POST",
				data: {
					responsavel : order.responsavel_preggo,

				}
			});
		},

		editStatus: function (order, user, item) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'edit/status/' + item,
				method: "POST",
				data: {
					status : order.status,
					user: user.name,

				}
			});
		},
		NewAnotation: function (anotation, user, item) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'new/anotation/' + item,
				method: "POST",
				data: {
					anotation : anotation.text,
					user: user.name,

				}
			});
		},
		editEntradaArmazem: function (order, item) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'edit/entrada_armazem/' + item,
				method: "POST",
				data: {
					entrada_armazem : order.ent_Armazem,
					valor_producao : order.valor_producao,
					desconto : order.desconto,
					faturado: order.faturado,
					pvp: order.pvp,
					desconto_pvp: order.desconto_pvp,
					preco_venda: order.preco_venda,
					data_pagamento: order.data_pagamento,
					penalizacao: order.penalizacao,
					pedido_pagamento: order.pedido_pagamento,

				}
			});
		},
		editFornecedores: function (order, item) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'edit/fornecedores/' + item,
				method: "POST",
				data: {
					fornecedores : order,

				}
			});
		},
		savePrediction: function(Prediction, id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'edit/prediction/' + id,
				method: "POST",
				data: {
					prediction : Prediction,

				}
			});
		},
		StatusEmbaladoChecked: function (id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'put/embalado/' + id,
				method: "POST",
			});
		},
		StatusEmbaladoNOTChecked: function (id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'put/not_embalado/' + id,
				method: "POST",
			});
		},
		StateChecked: function (id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'put/conferido/' + id,
				method: "POST",
			});
		},
		StateNOTChecked: function (id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'put/not_conferido/' + id,
				method: "POST",
			});
		},
		Pagamento60: function (id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'put/60days/' + id,
				method: "POST",
			});
		},
		Pagamento30: function (id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'put/30days/' + id,
				method: "POST",
			});
		},
		getStatus: function(id) {
			return $http.get(baseUrl + 'get/status/' + id);

		},
		arquivar: function(id, type) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'order/archive/' + id + '/' + type,
				method: "POST",
			});
		},
		arquivarPersonal: function(id, user_id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'order/archive_personal',
				method: "POST",
				data: {
					id_ : id,
					user_id: user_id

				}
			});
		},
		RemoveArquivo: function(id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'remove/archive/' + id,
				method: "POST",
			});
		},
		RemoveArquivoPersonal: function(id, user_id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'remove/archive_personal',
				method: "POST",
				data: {
					id_ : id,
					user_id: user_id

				}
			});
		},
		deleteOrder: function(id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'remove/order/' + id,
				method: "POST",
			});
		},
		changeRating: function(value, id) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'change/rating/' + id,
				method: "POST",
				data: {
					value : value,

				}
			});
		},


	};
}])