myApp.factory('queueModel', ['$http', function ($http) {
	return {
		getAdjudicationsQueue: function() {
			return $http.get(baseUrl + 'get/adjudications_queue');
		},
		getAdjudicationsQueue_history: function() {
			return $http.get(baseUrl + 'get/adjudications_queue_history');
		},
		getAdjudicationsQueueCount: function() {
			return $http.get(baseUrl + 'get/adjudications_queue_count', {ignoreLoadingBar: true});
		},
		NotaCredito : function(value, id, user, type) {
			return $http ({
				headers: {
					'Content-Type': 'application/json'
				},
				url: baseUrl + 'update/nota_credito',
				method: "POST",
				data: {
					value : value,
					id: id,
					user: user,
					type: type,

				}
			});
		},
	};
}])